@tailwind base;
@tailwind components;
@tailwind utilities;

/* Disable horizontal scroll to navigate between pages on entire website. This is a temporary fix until I can figure out how to do it on a page by page basis. */
html,
body {
  background-color: #f5f5f5;
}
/* Adds a border to the React Flow Selection Pane */
.react-flow__selection {
  border: 1px solid #818cf8; /* Custom border */
}
