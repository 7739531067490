/* BaseNode.module.css */
.nodeResizeHandle {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

.rotateHandle {
  position: absolute;
  width: 8px;
  height: 8px;
  background: #3367d9;
  left: 50%;
  top: -30px;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  cursor: alias;
}

.rotateHandle::after {
  content: '';
  display: block;
  position: absolute;
  width: 1px;
  height: 30px;
  background: #3367d9;
  left: 3.5px;
  top: 5px;
}
