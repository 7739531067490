.scroller {
  height: 1.5em;
  line-height: 1.5em;
  position: relative;
  overflow: hidden;
  font-size: 40px;
  text-align: center;
}
.scroller > span {
  position: relative;
  top: 0;
  animation: bounce 6s infinite;
  font-weight: bold;
  background-color: green;
}

@keyframes bounce {
  0% {
    top: 0;
  }
  25% {
    top: -1.2em;
  }
  50% {
    top: -2.4em;
  }
  75% {
    top: -3.6em;
  }
}
